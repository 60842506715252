@import "theme/default/variables";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html, body {
  background: var(--background-color);
  overflow-x: auto;
}

* {
  font-family: 'Poppins', sans-serif;
}

*:focus {
  outline: none;
}

.router-label {
  cursor: pointer;
}

.router-label:hover {
  color: var(--secondary-color) !important;
}

.secondary-color {
  color: var(--secondary-color);
}

// Button's styles

.ant-btn {
  border-radius: 5px !important;
  font-size: 13px;
}

.ant-btn-round {
  border-radius: 12px !important;
}

.ant-btn-secondary {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-secondary:focus, .ant-btn-secondary:hover {
  background: var(--transparent-primary-color);
  border-color: var(--transparent-primary-color);
}

// Scrollbar

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.x-scroll {
  overflow-x: auto;
}

// Wrapper's Styles

.wrapper-container {
  display: flex;
  justify-content: center;
  height: fit-content;
  min-height: 100%;
  flex: 1;

  .wrapper-box {
    width: 100%;
    max-width: 1920px;
  }
}

@media all and (max-width: $mobile-width) {
  .wrapper-container {
    .wrapper-box {
      max-width: 100%;
      //min-width: 250px;
    }
  }
}

// Drawer styles

.ant-drawer-content-wrapper {
  max-width: 100vw !important;
}

.ant-drawer-body {
  //overflow-x: hidden;
}

.drawer-footer {
  position: absolute;
  width: 100%;
  background: white;
  bottom: 0;
  left: 0;

  button {
    margin-right: 10px;
  }
}

.ant-drawer-mask {
  background: rgb(145, 145, 145, .30);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.ant-drawer-header {
  padding: 18px 24px;
}

@media all and (max-width: $mobile-width) {
  .ant-drawer-body {
    padding: 15px;
  }
}

// Modal styles

.help-icon {
  color: #AAAAAA;
  font-size: 16px;
  margin-left: 7px;
  cursor: pointer;
}

.ant-modal-footer {
  //border-top: none !important;
  //padding-bottom: 30px;
}

.ant-modal-mask {
  background: rgb(145, 145, 145, .30);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.ant-drawer-title {
  color: #5D5D5D;
  font-weight: 600;
  font-size: 18px;
}

.ant-modal-header {
  padding: 16px 0;
  margin: 0 24px;
}

.ant-modal-close {
  left: 0;
  right: unset;
}

.ant-modal-title {
  text-align: center;
  color: #5D5D5D;
  font-weight: 600;
}

.ant-modal-close-x {
  color: #5D5D5D;
  font-size: 13px;
}

.ant-modal-content {
  border-radius: 4px;
  box-shadow: 0 3px 7px rgb(238, 241, 247);
}

@media all and (max-width: $mobile-width) {

}

// Form Styles

nz-radio-group {

  .ant-radio-button-wrapper {
    background: rgba(67, 92, 122, 0.42) !important;
    color: white !important;
    border: none !important;
    font-size: 12px !important;
  }

  .ant-radio-button-wrapper-checked {
    border: none !important;

    .ant-radio-button {
      background: #3B5573;
      border-radius: 3px;
    }
  }

  .ant-radio-button-wrapper::before {
    background: none !important;
  }
}

.ant-select-dropdown {
  border-radius: 3px !important;
  box-shadow: 0 3px 24px rgba(148, 148, 148, 0.24);
}

.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-danger) {
  background-color: #f5f5f5 !important;
  color: #4C4C4C !important;

  i {
    color: #4C4C4C !important;
  }
}

.ant-select-item-option-selected {
  background-color: #426ea114 !important;

  i {
    color: #3B5573 !important;
  }
}

nz-form-label {
  label {
    color: #5D5D5D !important;
  }
}

.ant-form-item-control-input-content {
  input {
    border-radius: 3px;
  }
}

nz-select {
  nz-select-top-control {
    border-radius: 3px !important;
  }
}

.ant-input-group-addon {
  color: #acacac !important;
}

.ant-form-item-with-help {
  margin-bottom: 10px;
}

// Flex's Styles

.flex {
  display: flex;
}

.stretch {
  flex: 1;
}

.wrap {
  flex-wrap: wrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.no-wrap {
  flex-wrap: nowrap;
}

.flex.column {
  flex-direction: column;
}

.flex.align-center {
  align-items: center;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.center {
  justify-content: center;
}

.flex.between {
  justify-content: space-between;
}

.flex.around {
  justify-content: space-around;
}

.flex.evenly {
  justify-content: space-evenly;
}

.flex.end {
  justify-content: flex-end;
}

// Error styles

.error-box {
  margin-bottom: 15px;
  background-color: var(--secondary-color);
  padding: 0;
  border-radius: 4px;

  div {
    color: white;
  }
}

// Responsive Styles

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media all and (max-width: $mobile-width) {
  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}

// Image's Styles

.img-box {
  img {
    width: 100%;
  }
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// Page's Styles

.page-box {
  min-height: 100vh;

  .side-menu-box {
    position: sticky;
    // height: 100vh;
    overflow-y: auto;
    min-width: 215px;
    top: 0;
    z-index: 2;
  }

  .page-box-content {
    //overflow-x: auto;
    z-index: 1;
    position: relative;
    max-height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .page-box-content::-webkit-scrollbar {
    display: none;
  }
}

.page-header {
  .breadcrumb {
    margin-bottom: 5px;

    nz-breadcrumb {
      font-size: 12px !important;
    }
  }

  .header-title {
    font-size: 24px;
    color: #3C3C3C;
    font-weight: bold;
    margin: 5px 0;
  }

  button {
    margin: 5px 0;
  }
}

.page-container {
  padding: 0 30px 15px 30px;
  //overflow-x: hidden;

  .page-content {
    min-width: 576px;
  }
}


@media all and (max-width: $mobile-width) {
  .page-box {
    .side-menu-box {
      position: fixed;
      top: -1px;
      left: 0;
      width: 0;
    }
  }

  .page-header {
    .breadcrumb {
      margin-bottom: 0px;

      nz-breadcrumb {
        font-size: 10px !important;
      }
    }

    .header-row {
      justify-content: flex-end;
    }

    .header-title {
      font-size: 20px;
      margin: 5px 0;
      width: 100%;
      text-align: left;
    }

    button {
      margin: 5px 0;
    }
  }

  .page-container {
    padding: 0 18px 18px 18px;

    .page-content {
      min-width: unset;
    }
  }
}

// card

.global-card {
  border-radius: 9px;
  background-color: white;
  box-shadow: 0 0 6px rgba(138, 138, 138, 0.12);
  border: solid 1px rgba(151, 151, 151, 0.12);
  width: 100%;
  min-height: 100px;
}

// orders

.order1 {
  order: 1;
}

.order2 {
  order: 2;
}

.order3 {
  order: 3;
}

.order4 {
  order: 4;
}

.order5 {
  order: 5;
}

// popover

.ant-popover-inner {
  border-radius: 12px;
}

.btns-box {
  .btn {
    display: flex;
    justify-items: center;
    cursor: pointer;
    margin-bottom: 4px;

    i {
      font-size: 12px;
      margin: auto;
    }

    span {
      color: #CECECE;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .btn:hover {
    i {
      color: var(--secondary-color);
    }

    span {
      color: #ffdfdf;
    }
  }
}

// Modal's Styles

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

.ant-modal-title {
  color: #5D5D5D;
  font-size: 19px;
  font-weight: 500;
}

.ant-modal-close {
  left: 0;

  i {
    color: #5D5D5D;
    font-size: 14px;
    svg {
      * {
        fill: #5D5D5D !important;
      }
    }
  }
}

// empty

.empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  i {
    color: #C0C4CB;
    font-size: 70px;
  }

  .message-box {
    color: #C0C4CB;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
}

// stepper styles

.ant-steps-item-title {
  color: #AAAAAA;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #3B5573;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #3B5573;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #3B5573;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #3B5573;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #3B5573;
}

.ant-steps-vertical {
  height: 220px;
}

.meeting-details-wrap {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.drawer-details-wrap {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

// notifications

.ant-notification-hook-holder, .ant-notification-notice {
  width: fit-content !important;
  max-width: calc(100vw - 24px * 2);
}

// timer input

.ant-picker-ranges {
  .ant-picker-now {
    display: none !important;
  }
}

// status colors

.red-s {
  color: #FC6D4F !important;
}

.green-s {
  color: #76CF72 !important;
}

.yellow-s {
  color: #ffc500 !important;
}

// date range picker responsive

@media all and (max-width: 500px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap !important;
    overflow-y: scroll !important;
    max-height: calc(100vh - 400px) !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  .ant-picker-panel-container::-webkit-scrollbar {
    display: none !important;
  }

  .ant-picker-panels::-webkit-scrollbar {
    display: none !important;
  }

  .ant-picker-panel-container .ant-picker-panel {
    margin: auto !important;
  }
}
