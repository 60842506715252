
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #FF8484;
@secondary-color: #3B5573;
@background-color: #FFFFFF;
@light-gray-color: rgba(117, 117, 117, 0.75);

@body-background: @background-color;
@layout-body-background: @background-color;
@tooltip-color: @light-gray-color;
@font-family: 'Poppins', sans-serif;
@form-item-label-font-size: 14px;
