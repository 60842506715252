
$mobile-width: 612px;
$tablet-width: 768px;
$desktop-width: 1024px;
$max-width: 1200px;

:root {
  --background-color: #FFFFFF;
  --primary-color: #3B5573;
  --transparent-primary-color: rgba(59, 85, 115, 0.80);
  --secondary-color: #FF8484;
  --light-gray-color: rgba(117, 117, 117, 0.75);
}
